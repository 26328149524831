import { leaseSectorOptions } from '~/constants/LeaseSector'
import {
  type IFilterValue,
  PropertyLeasePortfolioFilter,
  useApiPortfolioFilters,
} from '@portfolio'

export function useLeaseFilters() {
  const route = useRoute('division-companyId-portfolio')
  const companyId = route.params.companyId

  const { data: statuses, isFetching: isFetchingStatuses } =
    useApiPortfolioFilters(companyId, PropertyLeasePortfolioFilter.STATUS)
  const { data: sectors, isFetching: isFetchingSectors } =
    useApiPortfolioFilters(companyId, PropertyLeasePortfolioFilter.SECTOR)
  const { data: states, isFetching: isFetchingStates } = useApiPortfolioFilters(
    companyId,
    PropertyLeasePortfolioFilter.STATE,
  )

  const { allFields, isFetching: isFetchingFields } =
    useApiLeaseFieldGroupsByCompanyId(companyId)
  const fields = computed(() =>
    allFields.value.map((field) => ({
      label: field.name,
      value: camel(field.id),
    })),
  )

  return {
    statuses: computed(() =>
      Object.keys(statuses.value?.items ?? {}).map((option) => ({
        label: title(option),
        value: camel(option),
      })),
    ),
    sectors: leaseSectorOptions,
    states: computed(() => parseOptions(states.value?.items ?? {})),
    fields,
    isFetchingStatuses,
    isFetchingSectors,
    isFetchingStates,
    isFetchingFields,
  }
}

function pascalToCamel(pascal: string) {
  return pascal.charAt(0).toLowerCase() + pascal.slice(1)
}

function parseOptions(
  options: Record<string, IFilterValue>,
  labelMap?: Record<string, string>,
) {
  return Object.keys(options).map((option) => {
    let label = option
    let value = option

    if (option === '[none]') {
      label = 'None'
      value = 'null'
    }

    return {
      label: labelMap ? labelMap[pascalToCamel(label)] : label,
      value,
    }
  })
}
